.header {
    &-menu {
        border-bottom: solid 1px #dcdcdc;

        &-text {
            color: #27292C;
            cursor: pointer;
            transition-duration: 0.4s;
            transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
        }

        &-subtext {
            color: #404040;
        }

        &-extended {
            box-shadow: 0px 3px 6px #00000029;

            &-container {
                transition-duration: 0.4s;
                transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
                transform-origin: top;
            }

            &-border {
                height: 1px;
                width: 93%;
                background-color: #dcdcdc;
                position: absolute;
                bottom: 0px;
            }
        }

        &-cover {
            height: calc(100vh - 65px);
            width: 100%;
            z-index: 100;
        }
    }
}
.text-sticky {
    color: #ffffff !important;
}

.not-show {
    display: none;
}

.is-sticky {
    display: block !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    color: #ffffff;
    background-color: #C98468 !important;
    animation: 1500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
}

.is-non-sticky {
    display: none;
}


.menu-scrollbar {
    /* width */
    &::-webkit-scrollbar {
        height: 5px;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
        background: white;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #C98468;
    }
  
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #C98468;
    }
  }